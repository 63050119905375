<template>
  <div class="redo">
    <a @click="redo">
      <img src="@/assets/images/icons/redo.png" alt />
      {{$t('result.redo')}}
    </a>
  </div>
</template>

<script>
export default {
  name: "Redo",
  methods: {
    redo() {
        this.$router.push(`/`);
    }
  },
};
</script>

<style lang="scss" scoped>
.redo {
  a {
    text-decoration: none;
    color: white;
    font-family: Gotham;
    font-size: 14px;
    line-height: 20px;
    img {
      vertical-align: middle;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }
  a:hover{
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    color: #fcd500;
    img{
    transition: filter 0.3s ease-in-out;
    filter: invert(100%);
    animation: 0.75s rotate360;
    }
  }
}
</style>
