<template>
  <div class="share">
    <span>{{$t('result.share')}}</span>
    <a @click="facebook"> 
      <img src="@/assets/images/icons/facebook.png" alt />
    </a>
    <a @click="twitter"> 
      <img src="@/assets/images/icons/twitter.png" alt />
    </a>
    <a @click="whatsapp"> 
      <img src="@/assets/images/icons/whatsapp.png" alt />
    </a>
  </div>
</template>

<script>
export default {
  name: "Share",
  data() {
    return {
      shareText: this.$t("result.shareText"),
      shareLink: `${window.location.origin}/${
        window.location.pathname.split("/")[1]
      }/`,
      playlist: null,
    };
  },
  methods: {
    facebook() {
      const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.shareLink
      )}`;
      window.open(
        url,
        "facebook",
        `width=550,height=450,scrollbars=yes,toolbar=no,location=yes`
      );
    },
    twitter() {
      const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        this.shareText
      )}&url=${encodeURIComponent(this.shareLink)}`;
      window.open(
        url,
        "twitter",
        `width=550,height=450,scrollbars=yes,toolbar=no,location=yes`
      );

    },
    whatsapp() {
      const url = `https://wa.me/?text=${encodeURIComponent(
        `${this.shareText} ${this.shareLink}`
      )}`;
      window.open(url);
    }
  },
};
</script>

<style lang="scss" scoped>
.share {
    @include desktop-up {
      display: flex;
      margin-right: 26px;
    }
  span {
    color: white;
    margin-top: 16px;
    font-family: Gotham;
    font-size: 14px;
    line-height: 20px;
    display: block;
    @include desktop-up {
      margin-top: 42px;
      margin-right: 26px;
    }
  }
  img {
    height: 24px;
    width: 24px;
    margin: 16px 16px 16px 16px;
    @include desktop-up {
      margin-top: 42px; 
      margin-right: 32px;
    }
  }
  a:hover{
    cursor: pointer;
    transition: filter 0.3s ease-in-out;
    filter: invert(100%);
    animation: 0.75s socialspop;
  }
}
</style>
