<template>
    <div class="topbar">
        <img src="@/assets/images/logos/mainlogo.png" alt="NYP" class="logo" />
        <div class="redo hideonmobile">
          <a @click="redo">
            <img src="@/assets/images/icons/redo.png" alt />
            {{$t('result.redo')}}
          </a>
        </div>
    </div>
</template>

<script>
export default {
  name: "ResultTopbar",
  methods: {
    redo() {
        this.$router.push(`/`);
    }
  },
};
</script>

<style lang="scss" scoped>

  .topbar {
    display: none;
    position: absolute;
    top: 24px;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
      padding: 0px 24px;
      max-width: 1400px;
      @include desktop-down{
        justify-content: center;
      }
    .logo{
        display: none;
        width: 224px;
        height: auto;
        display: flex;
      @include desktop-down{
        width: 160px;
        height: auto;
      }
    }
    .hideonmobile{
      @include tablet-down{
        display: none;
      }
    }
  }
  .redo {
    display: none;
      display: flex;
      margin-left: auto;
  a {
      text-decoration: none;
      color: white;
      font-family: Gotham;
      font-size: 14px;
      line-height: 20px;
    img {
        vertical-align: middle;
        height: 24px;
        width: 24px;
        margin-right: 8px;
    }
  }
  a:hover{
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      color: #fcd500;
    img{
        transition: filter 0.3s ease-in-out;
        filter: invert(100%);
        animation: 0.75s rotate360;
    }
  }
}
</style>