<template>
  <div class="page home">
    <LanguageSwitcher />
    <section class="login">
      <div class="page_holder">
        <img src="@/assets/images/logos/mainlogo.png" alt="NYP" class="logo" />
        <h1 class="title">{{ $t('home.title') }}</h1>
        <p class="subtitle">{{ $t('home.subtitle') }}</p>
        <div class="checkboxes-holder">
          <label class="checkbox-holder" id="termbox">
            <span class="checkbox-description" v-html="$t('home.terms')"></span>
            <input type="checkbox" id="terms" name="terms" v-model="termsOptin" />
            <span class="checkbox"></span>
          </label>
        </div>
        <SpotifyButton :class="{ disabled: !termsOptin }" :ButtonText="$t('home.spotify')"
          v-on:click.native="spotifyConnect"></SpotifyButton>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import LanguageSwitcher from "../components/LanguageSwitcher.vue";
import Footer from "../components/Footer.vue";
import SpotifyButton from "../components/SpotifyButton.vue";
export default {
  components: { LanguageSwitcher, SpotifyButton, Footer },
  name: "Home",
  data() {
    return {
      termsOptin: false,
      commercialOptin: 0,
      loginError: false
    };
  },
  methods: {
    spotifyConnect() {
      if (this.termsOptin == true) {
        const clientId = "f0569528e88249b09636322a785ab05b";
        const redirectUri = window.location.href;
        const scope = "playlist-modify-private";
        window.location = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&scope=${scope}&redirect_uri=${redirectUri}`;
      }
    }
  },
  async created() {

    var params = this.$route.hash;
    // if (this.$route.query.code) {
    if (params.includes("access_token")) {
      localStorage.setItem('userAuthInfo', params)
      this.$router.push(`quiz`);
    } else {
      if (Object.keys(this.$route.query).length != 0) window.location = window.location.href.split("?")[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  padding: 80px 8px 120px 8px;
  position: relative;
  overflow: hidden;
  background-image: url("../assets/images/backgrounds/main-mobile.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;

  @include desktop-up {
    background-image: url("../assets/images/backgrounds/main-desktop.jpg");
  }

  @include mobile {
    padding-bottom: 80px;
  }

  .login {
    max-width: 400px;

    @include desktop-up {
      max-width: 620px;
    }
  }

}

.disabled {
  opacity: 0.5;
}

.page_holder {
  color: white;
  text-align: center;

  .logo {
    display: block;
    margin: 0 auto 50px;
    width: 180px;

    @include desktop-up {
      width: 240px;
      margin: 0 auto 72px;
    }
  }

  .title {
    color: white;
    text-align: center;
    font-family: Tungsten;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    margin-bottom: 24px;

    @include desktop-up {
      margin-bottom: 40px;
      font-size: 56px;
      line-height: 56px;
    }
  }

  .subtitle {
    color: white;
    margin-bottom: 32px;
    text-align: center;
    font-family: Gotham;
    font-size: 14px;
    line-height: 20px;
  }

  .checkboxes-holder {
    font-family: Gotham;
    font-size: 12px;
    line-height: 20px;

    .checkbox-holder {
      margin-top: 24px;
      margin-bottom: 16px;
      text-align: center;
      display: block;
      position: relative;
      cursor: pointer;
      user-select: none;
      color: #ffffff;

      &.active {
        animation: infinite ease 5s fade;
      }

      @keyframes fade {
        from {
          background-color: rgba(212, 80, 80, 0.3);
        }

        to {
          background-color: rgba(242, 245, 169, 0);
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 2px;
    }

    .checkbox-holder:hover input~.checkbox {
      transition: background-color 0.3s ease-in-out;
      background-color: rgba(255, 230, 0, 0.6);
    }

    .checkbox-holder input~.checkbox {
      background-color: #fcd500;
    }

    .checkbox:after {
      content: "";
      position: absolute;
      display: none;
    }

    .checkbox-holder input:checked~.checkbox:after {
      display: block;
    }

    .checkbox-holder .checkbox:after {
      margin: 3px;
      left: 3px;
      top: 0px;
      width: 5px;
      height: 8px;
      border: solid rgb(1, 99, 45);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .checkbox-description {
      text-align: left;
      display: block;
      margin-left: 20px;
      margin-left: 32px;
    }
  }
}
</style>
