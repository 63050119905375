<template>
  <span class="footer" v-html="$t('footer.terms')">
  </span>
</template>

<script>
export default {
  name: "Footer",
  props: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 24px;
  color: white;
  font-size: 14px;
  @include tablet-down{
    font-size: 10px;
    bottom: 12px;
  }
  &:hover{
    cursor: pointer;
  }
}
</style>
