<template>
    <div class="topbar">
      <img src="@/assets/images/logos/mainlogo.png" alt="NYP" class="logo" />
      <p class="steps hideondesktop">{{$t('quiz.step')}} {{currentQuestion}}/4</p>
      <div class="topbar-slides hideondesktop">
        <div
          class="progress hideondesktop"
          :style="{'background-image': 'url(' + require('@/assets/images/steps/' + currentQuestion + '.png') + ')'}"
        ></div>
      </div>
    </div>
</template>

<script>
export default {
  name: "QuizTopbar",
  props: {
    currentQuestion: Number,
  },
};
</script>

<style lang="scss" scoped>

  .topbar {
    position: absolute;
    top: 16px;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
    @include tablet-up {
      top: 24px;
      padding: 0px 24px;
      max-width: 1400px;
    }
    .logo{
      margin-right: auto;
      margin-left: auto;
      width: 180px;
      height: auto;
      @include tablet-up {
      margin-right: auto;
      margin-left: 0px;
        display: block;
        width: 224px;
        height: auto;
      }
    }
    .steps {
      font-family: Gotham;
      font-size: 14px;
      line-height: 20px;
      margin-left: auto;
      margin-right: 32px;
      color: white;
    }
    .topbar-slides {
      .progress {
        width: 50px;
        height: 50px;
        background-size: 50px 50px;
      }
    }
    .hideondesktop{
      @include tablet-down{
        display: none;
      }
    }
  }
</style>