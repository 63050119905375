import axios from 'axios';
import store from './../store'


export async function getFeaturedTracks(tracklist) {
  const featuredTracks = tracklist.data.data;
  const uris = featuredTracks.map(item => item.uri.replace('spotify:track:', '')).join(',');

  const response = await axios.get(`https://api.spotify.com/v1/tracks?ids=${uris}`, {
    headers: {
      Authorization: `Bearer ${store.getters.getSpotifyAccessToken}`
    }
  })

  return response;
}

export async function searchTracks(inputSearch) {
  const response = await axios.get(`https://api.spotify.com/v1/search?q=${inputSearch}&type=track&limit=15`, {
    headers: {
      Authorization: `Bearer ${store.getters.getSpotifyAccessToken}`
    }
  })

  return response;
}

export async function followPlaylist(id) {
  const userAuthInfo = localStorage.getItem('userAuthInfo')
  if (userAuthInfo) {
    var tokenString = userAuthInfo.substring(1)
    var token = tokenString.split('=')[1].split('&')[0]
  }
  const data = {
      "public": false
  };

  const response = await axios.put(`https://api.spotify.com/v1/playlists/${id}/followers`, data, {
      headers: {
          Authorization: `Bearer ${token}`
      }
  })

  return response;
}


export async function searchArtists(inputSearch) {
  const response = await axios.get(`https://api.spotify.com/v1/search?q=${inputSearch}&type=artist&limit=11`, {
    headers: {
      Authorization: `Bearer ${store.getters.getSpotifyAccessToken}`
    }
  })

  if (response.status == 429) {
    console.error('spotify rate limit reached')
    return { error: 'rate-limit' };
  } else {
    return response.data.artists;
  }
}

export async function followArtist(id) {
  const response = await axios.put(`https://api.spotify.com/v1/me/following?type=user&ids=${id}`, {}, {
    headers: {
      'Authorization': `Bearer ${store.getters.getSpotifyAccessToken}`
    }
  })

  return response;
}

export async function saveTrackToFavorites(id) {
  const response = await axios.put(`https://api.spotify.com/v1/me/tracks?ids=${id}`, {}, {
    headers: {
      'Authorization': `Bearer ${store.getters.getSpotifyAccessToken}`
    }
  })

  return response;
}

export async function getAccount() {
  try {
    const response = await axios.get(`https://api.spotify.com/v1/me`, {
      headers: {
        Authorization: `Bearer ${store.getters.getSpotifyAccessToken}`
      }
    })

    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      console.log('Error', error.message)
    }
  }
}


export async function createPlaylist(user, name, description) {
  const data = {
    "name": name,
    "description": description,
    "public": true
  }

  const response = await axios.post(`https://api.spotify.com/v1/users/${user.id}/playlists`, data, {
    headers: {
      'Authorization': `Bearer ${store.getters.getSpotifyAccessToken}`,
      'Content-Type': 'application/json'
    }
  })

  return response.data.id;
}

export async function getPlaylist(id) {
  const response = await axios.get(`https://api.spotify.com/v1/playlists/${id}`, {
    headers: { Authorization: `Bearer ${store.getters.getSpotifyAccessToken}` }
  })

  return response.data;
}

export async function getArtists() {
  const response = await axios.get(`https://api.spotify.com/v1/me/top/artists?time_range=short_term`, {
    headers: {
      Authorization: `Bearer ${store.getters.getSpotifyAccessToken}`
    }
  })

  let artists = [];
  response.data.items.map(item => {
    artists.push(item.id)
  })

  const removeDuplicates = [...new Set(artists)];

  return removeDuplicates;
}
