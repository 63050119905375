import nl from './assets/languages/nl.json'
import de from './assets/languages/de.json'
import be from './assets/languages/be.json'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)
export default new VueI18n({
    locale: 'nl',
    fallbackLocale: 'nl',  
    messages: {
        nl: nl,
        be: be,
        de: de
    }
})