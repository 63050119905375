<template>
  <div
    class="answer-title"
    id="answer"
    @click="onSelectAnswer(title)"
    v-bind:style="{ 'background-image': 'url(' + require('@/assets/images/quiz/' + answerImage + '.jpg') + ')'}"
  >
    <div class="answer-banner">
      <h1>
        <span>{{title}}</span>
        <br />
      </h1>
    </div>

  </div>
</template>

<script>
export default {
  name: "Answer",
  props: {
    title: String,
    answerImage: String
  },
  methods: {
    onSelectAnswer(answer) {
      this.$store.state.answers.push(answer);
      this.$emit("clickedOnAnswer");
    }
  },
};
</script>

<style lang="scss" scoped>
.answer-title {
  display: flex;
  flex-direction: row;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px 0px 8px 0px;
  margin-bottom: 12px;
  height: 120px;
  width: 100%;


  @include desktop-up {
    margin-left: 16px;
    max-width: 800px;
    height: 160px;
    width: 456px;
  }
  .answer-banner {
    animation: 1s ease-in-out 0s 1 slideInFromLeft;
    margin-left: 8px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      display: inline-block;
      text-transform: uppercase;
      transform: skewY(-8deg);
    }
    h1 span {
      display: inline-block;
      padding: 8px;
      color: white;
      background-color: red;
      border-radius: 16px 0px 16px 0px;
    }
  }
  span {
    font-family: Gotham;
    font-size: 16px;
    align-self: flex-end;
    color: white;
  }
}
.answer-title:hover {
  @include mobile-up{
    cursor: pointer;
    h1 span {
      animation: 1s labelchange;
      animation-fill-mode: forwards;  
    }
  }
}
</style>
