<template>
    <div class="bottomBar">
      <p class="steps">{{$t('quiz.step')}} {{currentQuestion}}/4</p>
      <div class="topbar-slides">
        <div
          class="progress"
          :style="{'background-image': 'url(' + require('@/assets/images/steps/' + currentQuestion + '.png') + ')'}"
        ></div>
      </div>
    </div>
</template>

<script>
export default {
  name: "StepCounter",
  props: {
    currentQuestion: Number,
  },
};
</script>

<style lang="scss" scoped>

  .bottomBar {
    bottom: 24px;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
    justify-content: center;
    @include desktop-up {
      padding: 0px 24px;
      max-width: 1400px;
    }
    .steps {
      font-family: Gotham;
      font-size: 14px;
      line-height: 20px;
      margin-right: 32px;
      color: white;
    }
    .topbar-slides {
      .progress {
        width: 50px;
        height: 50px;
        background-size: 50px 50px;
      }
    }
  }
</style>