<template>
    <div class="language-picker">
        <form action="" class="language-picker_form">
            <select name="language-picker-select" @change="changeLanguage" id="language-picker-select">
                <option value="de">DE</option>
                <option value="nl">NL</option>
            </select>
        </form>
    </div>
</template>

<script>
export default {
    methods:{
        changeLanguage(obj){
          if(obj.target.value == 'de')
              this.$i18n.locale = 'de'
          else if(obj.target.value == 'nl')
              this.$i18n.locale = 'nl'
          else if(obj.target.value == 'be')
              this.$i18n.locale = 'be'
        }
    },
    mounted(){
      let element =  document.getElementById("language-picker-select");
      const currentLang = this.$i18n.locale || 'de'
      element.value = currentLang
    }
};
</script>

<style scoped lang="scss">
 .language-picker{
    position: absolute;
    top: 32px;
    right: 32px;
    @include tablet-down{
        top: 16px;
        right: 16px;
    }
}
    
.language-picker_form{
    background: transparent;
    border: 0px;
    border-bottom: 2px solid white;
}

select{
    background: transparent;
    outline: none;
    border: none;
    width: 58px;
    color: white;
    font-family: Gotham;
}
option {
  background: #5ea572;
  color: white;
}
</style>

