<template>
    <Button class="button-spotify">
        <img src="@/assets/images/icons/spotify.png" alt="" class="icon-spotify">
        {{ButtonText}}
    </Button>
</template>

<script>
export default {
  name: "SpotifyButton",
  props: {
    ButtonText: String,
  },
};
</script>

<style lang="scss" scoped>
.button-spotify {
      font-family: Gotham;
      font-size: 16px;
      line-height: 24px;
      margin-top: 24px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 900;
      height: 48px;
      background-color: #fcd500;
      border-radius: 50px;
      border: none;
      color: black;
        width: 350px;
      @include mobile-down{
        width: 300px;
      }
      .icon-spotify{
        vertical-align: middle;
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
    .button-spotify:hover {
      transition: opacity 0.2s ease-out;
      opacity: 0.9;
      .icon-spotify{
            animation: 0.75s sizepop;
      }
    }
</style>
