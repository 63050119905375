<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    const domain1 = window.location.hostname.split(".")[0];
    const domain2 = window.location.hostname.split(".")[1];
    if (domain1 === "wachtmuziek" || domain2 === "wachtmuziek")
      this.$i18n.locale = "nl";
    else if (domain1 === "wachtmuziekje" || domain2 === "wachtmuziekje")
      this.$i18n.locale = "be";
    else if (domain1 === "wartezimmermusik" || domain2 === "wartezimmermusik")
      this.$i18n.locale = "de";
  }
};
</script>

<style lang="scss">
.page {
  min-height: 100vh;
  height: 100%;
}
</style>
