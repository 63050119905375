<template>
  <div class="page result">
    <Topbar/>
    <section class="page_holder">
        <div class="left-holder">
          <h1 class="title">{{$t('result.title')}}</h1>
          <h1 class="description">{{$t('result.subtitle')}}</h1>
          <SpotifyButton v-on:click.native="spotifyListen" :ButtonText="$t('result.button')"/>
          <share class="hideOnMobile"></share>
        </div>

        <div class="right-holder">
          <iframe
            class="iframe"
            :src="playlsitEmbeded"
            width="100%"
            height="380"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
        
        <share class="hideOnDesktop"></share>
        <redo class="hideOnDesktop"></redo>

    </section>
    <Footer/>
  </div>
</template>

<script>
import * as SpotifyHelper from "@/helpers/spotify.js";
import Topbar from "../components/ResultTopbar.vue";
import SpotifyButton from "../components/SpotifyButton.vue";
import Footer from "../components/Footer.vue";
import Redo from "../components/Redo.vue";
import Share from "../components/Share.vue";
export default {
  components: { Redo, Share, Topbar, SpotifyButton, Footer },
  name: "Home",
  data() {
    return {
      termsOptin: false,
      commercialOptin: 0,
      loginError: false,
      playlistId: null,
      playlistLink: null,
      playlsitEmbeded: null
    };
  },
  mounted(){
    var playlists =  ['6L3AfqkHC0WX3YKOpX9Zrq', '5fu3NHwYio0H8W328ddZLa', '76y3kq4643sgA2A2cXi4hQ'];
    this.playlistId = playlists[this.$store.state.playlistAnswer];
    var playlistLink = "https://open.spotify.com/embed/playlist/" + this.playlistId + "?utm_source=generator&theme=0";
    this.playlsitEmbeded = playlistLink;
    SpotifyHelper.followPlaylist(this.playlistId)
  },
  methods: {
    async spotifyListen() {
      let link = document.createElement("a");
      link.href = `https://open.spotify.com/playlist/${this.playlistId}`;
      link.target = "_blank";
      link.click();
    },
  },
  async created() {
  }
};
</script>

<style lang="scss" scoped>
.result {
  display: flex;
  padding: 80px 8px 32px 8px;
  position: relative;
  overflow: hidden;
  background-image: url("../assets/images/backgrounds/main-mobile.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include desktop-up {
    text-align: left;
    background-image: url("../assets/images/backgrounds/main-desktop.jpg");
  }

  .page_holder {
    @include desktop-up {
      margin-top: 24px;
      display: flex; 
      flex-direction: row;
      align-items: center;
      flex-basis: 80%;
      max-width: 800px;
    }
    .left-holder{
      @include desktop-up {
        width: 50%;
      }
      .title {
        font-family: Tungsten;
        font-size: 36px;
        line-height: 36px;
        color: white;
        margin-bottom: 24px;
        text-align: center;
        text-transform: uppercase;
        @include desktop-up {
        text-align: left;
          font-size: 56px;
          line-height: 54px;
          margin-bottom: 40px;
        }
      }

      .description {
        font-family: Gotham;
        font-weight: 100;
        font-size: 14px;
        line-height: 20px;
        color: white;
        margin-bottom: 0px;
        text-align: center;
        @include desktop-up {
        text-align: left;
          margin-right: 24px;
          margin-bottom: 28px;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    .right-holder{
      @include desktop-up {
        margin-left: auto;
        width: 50%;
      }
      .iframe{
        background-color: rgba(0, 0, 0, 0.2);
        margin-top: 24px;
        border-radius: .75rem;
        @include desktop-up {
          float: right;
        margin-top: 0px;
          height: 480px;
          max-width: 360px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .hideOnMobile{
    @include desktop-down {
      display: none;
    }
  }
  .hideOnDesktop{
    @include desktop-up {
      display: none;
    }
  }
}
</style>
