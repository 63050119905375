<template>
  <div class="page quiz">

    <Topbar :currentQuestion="currentQuestion + 1"></Topbar>
    
    <section class="question">
      <h3 class="question-title">{{$t(`quiz.questions[${currentQuestion}].question`)}}</h3>
      <div class="answers">
        <div v-for="(answer,index) in $t(`quiz.questions[${currentQuestion}].answers`)" :key=index>
          <Answer
          class="answer"
          :answerImage="answerImageSrc(index)"
          :currentQuestion="currentQuestion"
          :title="answer"
          @clickedOnAnswer="onClickAnswer(index)"
        ></Answer>
        </div>
      </div>
    <StepCounter class="hideondesktop" :currentQuestion="currentQuestion + 1"></StepCounter>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Topbar from "../components/QuizTopbar.vue";
import StepCounter from "../components/StepCounter.vue";
import Footer from "../components/Footer.vue";
import Answer from "../components/Answer.vue";
export default {
  components: { Answer, Topbar, StepCounter, Footer },
  name: "Quiz",
  data() {
    return {
      selectedAnswer: "",
      answerImageA: "q1a",
      answerImageB: "q1b",
      answerImageC: "q1c",
      currentQuestion: 0,
      imageSrc: [
        {
          A: 'q1a',
          B: 'q1b',
          C: 'q1c'
        },
        {
          A: 'q2a',
          B: 'q2b',
          C: 'q2c'
        },
        {
          A: 'q3a',
          B: 'q3b',
          C: 'q3c'
        },
        {
          A: 'q4a',
          B: 'q4b',
          C: 'q4c'
        }
      ]
    };
  },
  mounted() {
    // this.loadImage();
  },
  methods: {
    answerImageSrc (index) {
      if (index === 'A') {
        return this.answerImageA
      } else if (index === 'B') {
        return this.answerImageB
      } else if (index === 'C') {
        return this.answerImageC
      }
    },
    onSelectAnswer(answer) {
      console.log(answer, "answer");
    },
    async onClickAnswer(index) {
      if (this.currentQuestion <= 2) { 
        this.currentQuestion++;
        if(this.currentQuestion == 3){
          const numIndex = await this.convertIndex(index)
          this.$store.state.playlistAnswer = numIndex
        }
        this.answerImageA = this.imageSrc[this.currentQuestion].A
        this.answerImageB = this.imageSrc[this.currentQuestion].B
        this.answerImageC = this.imageSrc[this.currentQuestion].C
      } else {
        this.$router.push(`/result`);
      }
    },
    // loadImage() {
    //   this.answerImageA = "q1a";
    //   this.answerImageB = "q1b";
    //   this.answerImageC = "q1c";
    // },
    convertIndex(index) {
      if(index == "A") return 0
      else if(index == "B") return 1
      else return 2
    }
  }
};
</script>

<style lang="scss" scoped>
.quiz {
  position: relative;
  overflow: hidden;
  background-image: url("../assets/images/backgrounds/main-mobile.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    padding: 80px 0px 60px 0px;
  @include tablet-up {
    padding: 80px 8px 120px 8px;
    padding-top: 100px;
    padding-top: 168px;
    background-image: url("../assets/images/backgrounds/main-desktop.jpg");
  }
  .question {
    text-align: left;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 90%;
    @include desktop-up {
      flex-direction: row;
      max-width: 1000px;
    }
    .answers{
      @include desktop-up {
        margin-left: auto;  
      }
    }
    .question-title {
      font-family: Tungsten;
      font-size: 32px;
      line-height: 40px;
      color: white;
      margin-bottom: 16px;
      @include desktop-up {
        font-size: 56px;
        line-height: 56px;
        align-self: center;
        margin-bottom: 24px;
      }
    }
    .answer {
      margin-bottom: 32px;
    }
  }
  .hideondesktop{
    @include tablet-up{
      display: none;
    }
  }
  .hideonmobile{
    @include tablet-down{
      display: none;
    }
  }
}
</style>